<template>
  <div id="app">
    <header>
      <nav>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/login">Login</router-link></li>
        </ul>
      </nav>
    </header>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <p>&copy; 2024 深圳市鹏祥科技股份有限公司</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* General styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f0f0f0;
  /* Background color for the page */
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header and Navigation Styles */
header {
  background: rgba(255, 255, 255, 0.2);
  /* Glass effect background */
  backdrop-filter: blur(10px);
  /* Blur effect */
  color: white;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav li {
  margin: 0 1rem;
}

nav a {
  color: #1A73E8;
  /* Link color */
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s;
}

nav a:hover {
  color: #0c47a1;
  /* Darker shade on hover */
}

/* Main and Footer Styles */
main {
  flex: 1;
  padding: 2rem;
}

footer {
  background-color: #f1f1f1;
  padding: 1rem;
  text-align: center;
}
</style>
