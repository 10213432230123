<template>
    <div class="homepage">
        <div class="hero-section">
            <h1 class="hero-title">欢迎来到我的 damn ass Web</h1>
            <p class="hero-subtitle">通往信息和互动世界的门户。</p>
            <button class="hero-button" @click="goToLogin">Login</button>
            <button class="hero-button" @click="goToTest">{{ ttt }}</button>
            <button class="hero-button" @click="scrollToSection('about')">了解更多</button>
        </div>
        <div class="content-section" id="about">
            <h2 class="section-title">About Us</h2>
            <p class="section-content">我们致力于为我们的用户提供一流的最佳体验
                文章、互动功能和充满活力的社区。</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
const ttt = ref('');
export default {
    
    methods: {
        goToLogin() {
            this.$router.push('/login');
        },
        goToTest() {
            axios.get('http://localhost:8081/test')
               .then(response => {
                    console.log(response.data);
                    ttt.value = response.data;
                })
               .catch(error => {
                    console.log(error);
                });

        },
        scrollToSection(id) {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
};
</script>

<style scoped>
/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

/*===== VARIABLES CSS =====*/
:root {
    --first-color: #1A73E8;
    --input-color: #80868B;
    --border-color: #DADCE0;
    --body-font: "Roboto", sans-serif;
    --normal-font-size: 1rem;
    --small-font-size: .75rem;
}

/*===== BASE =====*/
*,
::before,
::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

/*===== HOMEPAGE =====*/
.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-image: url('../assets/【哲风壁纸】2024-08-06 19_46_40.png');
    /* Replace with your custom background */
    background-size: cover;
    background-position: center;
    height: 100vh;
    color: #fff;
}

.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* Optional: darken the background for better text visibility */
    padding: 2rem;
}

.hero-title {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1rem;
    animation: fadeIn 2s;
}

.hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    animation: fadeIn 3s;
}

.hero-button {
    padding: 1rem 2rem;
    background-color: var(--first-color);
    color: #fff;
    font-size: var(--normal-font-size);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    animation: fadeIn 4s;
}

.hero-button:hover {
    background-color: #0f5bbb;
    box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
}

.content-section {
    padding: 3rem 2rem;
    background-color: #fff;
    color: #000;
    text-align: left;
    width: 100%;
}

.section-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    animation: fadeIn 5s;
}

.section-content {
    font-size: var(--normal-font-size);
    animation: fadeIn 6s;
}

/*===== ANIMATIONS =====*/
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>
