<template>
  <div class="background">
    <div class="l-form">
      <form @submit.prevent="submitForm" class="form">
        <h1 class="form__title">Sign In</h1>

        <div class="form__div">
          <input type="text" v-model="email" class="form__input" placeholder=" ">
          <label for="" class="form__label">Email</label>
        </div>

        <div class="form__div">
          <input type="password" v-model="password" class="form__input" placeholder=" ">
          <label for="" class="form__label">Password</label>
        </div>

        <input type="submit" class="form__button" value="Sign In">
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    submitForm() {
      // Handle form submission logic
      console.log('Email:', this.email);
      console.log('Password:', this.password);
    }
  }
};
</script>

<style scoped>
/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

/*===== VARIABLES CSS =====*/
:root {
  --first-color: #1A73E8;
  --input-color: #80868B;
  --border-color: #DADCE0;
  --body-font: "Roboto", sans-serif;
  --normal-font-size: 1rem;
  --small-font-size: .75rem;
}

/*===== BASE =====*/
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

h1 {
  margin: 0;
}

/*===== FORM =====*/
.l-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form {
  width: 360px;
  padding: 3rem 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.2);
  /* 半透明背景 */
  backdrop-filter: blur(10px);
  /* 模糊效果 */
  box-shadow: 0 10px 25px rgba(92, 99, 105, 0.2);
  color: white;
  /* 白色文本 */
}

.form__title {
  font-weight: 500;
  margin-bottom: 2.5rem;
}

.form__div {
  position: relative;
  height: 52px;
  margin-bottom: 1.5rem;
}

.form__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: var(--normal-font-size);
  border: 2px solid var(--border-color);
  border-radius: 0.5rem;
  outline: none;
  padding: 1rem;
  background: none;
  color: white;
  /* 白色文本 */
  z-index: 1;
}

.form__label {
  position: absolute;
  left: 1rem;
  top: 1rem;
  padding: 0 0.25rem;
  background-color: transparent;
  color: var(--input-color);
  font-size: var(--normal-font-size);
  transition: 0.3s;
}

.form__button {
  display: block;
  margin-left: auto;
  padding: 0.75rem 2rem;
  outline: none;
  border: none;
  background-color: var(--first-color);
  color: #fff;
  font-size: var(--normal-font-size);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 0.3s;
}

.form__button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
}

/*Input focus move up label*/
.form__input:focus+.form__label {
  top: -0.5rem;
  left: 0.8rem;
  color: var(--first-color);
  font-size: var(--small-font-size);
  font-weight: 500;
  z-index: 10;
}

/*Input focus sticky top label*/
.form__input:not(:placeholder-shown).form__input:not(:focus)+.form__label {
  top: -0.5rem;
  left: 0.8rem;
  z-index: 10;
  font-size: var(--small-font-size);
  font-weight: 500;
}

/*Input focus*/
.form__input:focus {
  border: 2px solid var(--first-color);
}

.background {
  background-image: url('../assets/【哲风壁纸】2024-08-07 10_27_23.png');
  /* 自定义背景 */
  background-size: cover;
  background-position: center;
  height: 100vh;
}
</style>
