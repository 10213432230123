import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import LoginPage from '../components/HelloWorld.vue';
import LiuPage from '../components/LiuPage.vue';


const routes = [
    { path: '/', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '/liupage', component: LiuPage }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
